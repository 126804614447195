import React, { Fragment} from 'react';
import MetaData from './layout/MetaData';
import Header from './layout/Header';
import Featured from './featured/Featured';

function Home() {
  return (

    <Fragment>
      <MetaData title={'Buy best products online'} />
    <div>
      <Header />
      <Featured />
    </div>
    </Fragment>
  );
}

export default Home;
