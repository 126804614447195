import React from 'react';
import './App.css';
import Home from './components/Home';

function App() {
  return (
    <div className="app">
      <Home  />

      {/* <header className="App-header">
        <img src='' className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>

  );
}

export default App;
