import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import './form.css'

const Result = () => {
    return(
       <p>Your details have been saved and you would be notified once we launch</p>
    )
}

const Form = () => {

    const [result, setResult] = useState(false)
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_zxre8dn', 'template_zxm2jkc', form.current, 'FnfuQ-unIz2HW47Pg')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset();
          setResult(true);
      };


  return (
    <div className='containers'>
        <form ref={form} action="" onSubmit={sendEmail}>
            <div className='formWord'>
                <input type="text" className='input' placeholder="Full Name" name="fullName" required />
                <br />

                <input type="phone" className='input' placeholder="Phone Number" name="phone" required />
                <br />
    
                <input type="email" className='input' placeholder="Email Address" name="email" required />
            </div>

            <button className='buttons'>Submit</button>

            <div className='row'>{result ? <Result/> : null }</div>
        </form>
    </div>
  )
}

export default Form