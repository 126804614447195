import React from 'react'
import Form from '../form/Form'
import './featured.css'

const Featured = () => {
  return (
    <div className='contained'>
        <div className='content'>
            <h1 className='titles'>Hello 👋 and welcome to the Deluccis online store</h1>
            <p className='status'>We are currently undergoing construction at the moment but be sure to leave your details in the form below to receive a first time customer discount and be notified once we are up and running</p>
            <Form />
        </div>

    </div>
  )
}

export default Featured