import React from "react";
import './layout.css'

const Navbar = () => {
  return (
    <div className='container'>
      <div className='logo'>
       <div className='imaging'>
        <a href="/">
          <img src="/images/ds.svg" alt="" width="110" height="100" />
        </a>
       </div>
      </div>
    </div>
  );
};

export default Navbar;
